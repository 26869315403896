









import { Vue, Component } from "vue-property-decorator";
import { ExpertList2021 } from "@/components/home/expertList_2021";
@Component({
    components: {
        ExpertList2021,
    },
})
export default class ExpertList_2021 extends Vue {}
