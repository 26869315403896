










































import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";

@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem,
    },
})
export default class ExpertList_2021 extends Vue {
    data: {} = {
        leader: [
            {
                name: "郭向远",
                title: "中国科技评估与成果管理研究会理事长、中纪委驻科技部纪检组原组长",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/guoxiangyuan.jpg",
                type: "",
            },
            {
                name: "齐让",
                title: "中国科学技术指标研究会原理事长、中国科协原副主席",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/qirang.jpg",
                type: "",
            },
        ],
        members: [
            {
                name: "罗晖",
                title: "中国国际科技交流中心主任、中国科协新技术开发中心主任",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/luohui.jpg",
            },
            {
                name: "解敏",
                title: "国家科技评估中心主任、中国科技评估与成果管理研究会副理事长",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/xiemin.jpg",
            },
            {
                name: "靳晓明",
                title: "国际技术转移协作网络（ITTN）指导委员会荣誉主席",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/jinxiaoming.jpg",
            },
            {
                name: "谭华霖",
                title: "中国科协组织人事部副部长",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/tanhualin.jpg",
            },
            {
                name: "秦久怡",
                title: "中国科协新技术开发中心副主任",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/qinjiuyi.jpg",
            },
            {
                name: "韩军",
                title: "国家科技评估中心副总评估师、中国科技评估与成果管理研究会秘书长",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/hanjun.jpg",
            },
            {
                name: "石勇",
                title: "机械工业信息研究院副院长",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/shiyong.jpg",
            },
            {
                name: "李志民",
                title: "清华大学教授",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/lizhimin.jpg",
            },
            {
                name: "张彦奇",
                title: "中国科学院理化技术研究所产业策划处处长",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/zhangyanqi.jpg",
            },
            {
                name: "姚卫浩",
                title: "北京大学科技开发部部长",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/yaoweihao.jpg",
            },
            {
                name: "王燕",
                title: "清华大学技术转移研究院院长",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/wangyan.jpg",
            },
            {
                name: "陈柏强",
                title: "北京理工大学技术转移中心副主任",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/chenbaiqiang.jpg",
            },
            {
                name: "高旭东",
                title: "清华大学经管学院、苏世民书院教授",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/gaoxudong.jpg",
            },
            {
                name: "张璋",
                title: "国际技术转移协作网络（ITTN）秘书长",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/zhangzhang.jpg",
            },
            {
                name: "董红霞",
                title: "国家科技评估中心科技成果与技术评估部副部长",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/donghongxia.jpg",
            },
            {
                name: "封凯栋",
                title: "北京大学政府管理学院系主任",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/fengkaidong.jpg",
            },
            {
                name: "郭书贵",
                title: "中国技术交易所总裁",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/guoshugui.jpg",
            },
            {
                name: "朱希铎",
                title: "中关村天合科技成果转化促进中心主任",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/zhuxiduo.jpg",
            },
            {
                name: "高路",
                title: "华控基金投资总监，高级投资经理",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/gaolu.jpg",
            },
            {
                name: "罗林波",
                title: "中部知光技术转移有限公司总经理、湖北省知识产权与创新发展研究院副院长",
                headimg:
                    "https://ittn.oss-cn-beijing.aliyuncs.com/2021_baijia/%E4%B8%93%E5%AE%B6_2020/luolinbo.jpg",
            },
        ],
    };
}
